import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/12B/1.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';
import Archive2022 from 'views/ClassAssembly2023/Archive2022';

const ClassAssembly12B2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 2,
    },
    
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      A Balanced Way Of Life
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 12B Date: 6 June 2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        On the 6th of June 2023, the students of class 12B performed a skit on the topic “A Balanced Way Of life” 
        highlighting the importance of striking a balance between work and leisure. Their skit served as a cautionary 
        tale by highlighting the consequences of not leading a balanced life. The first and the second scene showcased 
        the extremes that is, all work and all play. The last scene represented a balanced way of life wherein one needs 
        to find a balance between academics and leisure. The skit was accompanied by melodious songs and a scintillating 
        dance, re-emphasising the importance of leading a balanced life.

 <br></br>
 This skit has definitely conveyed the message to all the students that they need to be focussed on their 
 academic pursuits and parallelly indulge in recreational activities to freshen their mind. This will help 
 them reach their desired goals.
        <br></br>
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Happiness is not a matter of intensity but of balance and order and rhythm and harmony”

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 400 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>

             <Box marginBottom={4}>
               <Archive2022 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly12B2023;